<template>
  <div class="calendar">
      <Breadcrumb v-bind:item="'Calendar'"/>
      <div class="calendar-wrap">
          <SectionHeader v-bind:title="this.title"/>
          <CalendarFeed/>
      </div>
  </div>
</template>
<script>
import Breadcrumb from '@/components/snippets/Breadcrumb.vue'
import SectionHeader from '@/components/snippets/SectionHeader.vue';
import CalendarFeed from '@/components/CalendarFeed.vue';

export default {
    components: { 
        Breadcrumb,
        SectionHeader,
        CalendarFeed
    },

    data() {
        return {
            title: 'Calendar'
        }
    },
}
</script>
